@media print {
    body {
        font-size: .9rem;
        min-width: 770px;
        margin: 0 auto;
        padding: .15in .5in !important;
    }

    h5,
    .h5 {
        font-size: 1rem;
    }

    .modal {
        display: none !important;
    }

    footer {
        display: none !important;
    }

    .alert {
        display: none;
    }

    div.item-detail {
        page-break-after: always;
    }

    .table {
        &.table-bordered {
          thead {
            tr {
                border: 1px solid var(--bs-border-color);
                border-width: var(--bs-border-width) 0 !important;
            }
      
            th {
                border: 1px solid var(--bs-border-color);
                border-width: 0 var(--bs-border-width) !important;
            }
          }
        }
    }
}