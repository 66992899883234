.typeahead-standalone {
  width: 100%;

  .tt-input {
    background-color: #f9f9f9;
  }

  .tt-list {
    z-index: 1050;
    padding: 10px 0;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, .36);

    div[class^=tt-] {
      padding: 2px 5px 2px 10px;
      font-size: 1rem;
    }

    .tt-suggestion.tt-selected,
    .tt-suggestion:hover {
      background-color: #f5f6fa;
    }

    .tt-suggestion .tt-highlight {
      font-weight: 700;
      color: #c93737;
    }

    .tt-footer {
      font-weight: 500;
      color: #c93737;
    }
  }
}
